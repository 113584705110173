import React, { createContext, useState } from "react";

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
    bloglist: {},
    loader: false,
  });

  // Function to update the user
  const updateUser = (user) => {
    setState((prevState) => ({ ...prevState, user }));
  };

  const updateLoader = (loader) => {
    setState((prevState) => ({ ...prevState, loader: loader }));
  };

  // Function to toggle the theme
  const updatebloglist = (list) => {
    setState((prevState) => ({
      ...prevState,
      bloglist: list,
    }));
  };

  return (
    <AppContext.Provider
      value={{ state, updateUser, updatebloglist, updateLoader }}
    >
      {children}
    </AppContext.Provider>
  );
};
