import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
`;

const MainContainer = styled.main`
  flex: 1;
  padding: 0 16px;
  max-width: 1024px;
  width: calc(100% - 32px);
  margin: auto;
`;

export { Container, MainContainer }
