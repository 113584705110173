import React, { useState, useEffect, useContext } from "react";
import { MainLayout } from "../../layouts/main/main";
import BlogList from "../../components/blogList/blogList";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

const Blog = () => {
  const { updateLoader } = useContext(AppContext);
  const [blogs, setBlogs] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  
  const getAllPostData = async () => {
    updateLoader(true);
    try {
      const response = await axios.get(
        `${apiUrl}/clevermediumBackend/getallpost.php`
      );
      setBlogs(response.data.data);
      updateLoader(false);
    } catch (error) {
      console.error("Error during authentication:", error.message);
      updateLoader(false);
    }
  };
  useEffect(() => {
    getAllPostData();
  }, []);
  return (
    <MainLayout>
      {Array.isArray(blogs) && <BlogList blogs={blogs} />}
    </MainLayout>
  );
};

export default Blog;
