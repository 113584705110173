import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetComponet = ({ title, description, keywords, path }) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={`${apiUrl}/blog/${path}`} />
    </Helmet>
  );
};

export default HelmetComponet;
