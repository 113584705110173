import { Stack } from "@mui/material";
import pageNotFound from "../../assets/images/page-not-found.svg";
import { PageNotFountIMG } from "./pageNotFoundStyle";

export const PageNotFound = () => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} height={'100vh'}>
      <PageNotFountIMG src={pageNotFound} />
    </Stack>
  );
};
