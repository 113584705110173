// pages/AdminSignIn.js
import React, { useContext, useState } from "react";
import axios from "axios";
import { login } from "../../routers/auth";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const AdminSignIn = () => {
  const { updateLoader } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const handleSignIn = async () => {
    // Perform validation if needed
    try {
      updateLoader(true);
      const response = await axios.post(
        `${apiUrl}/clevermediumBackend/login.php`,
        {
          email: email,
          password: password,
        }
      );
      const token = response.data.jwt;
      login(token);
      navigate("/admin/bloglist");
      updateLoader(false);
    } catch (error) {
      console.error("Error during authentication:", error.message);
      updateLoader(false);
    }
  };

  return (
    <div>
      <h1>Admin Sign In</h1>
      <form>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="button" onClick={handleSignIn}>
          Sign In
        </button>
      </form>
    </div>
  );
};

export default AdminSignIn;
