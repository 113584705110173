import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logout, isAuthenticated } from "../../routers/auth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DContainer } from "./adminblogStyle";
import Button from "@mui/material/Button";
import { Paper, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import logo from "../../assets/images/clevermedium-logo.png";
import AccountMenu from "../accountMenu/accountMenu";
import useConvertText from "./../../hooks/useConvertText";
import { AppContext } from "../../context/AppContext";
import DeleteIcon from "@mui/icons-material/Delete";

// const baseUrl = process.env.BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminBlog = () => {
  const { updateLoader } = useContext(AppContext);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const quillRef = useRef(null);
  const authenticated = isAuthenticated();
  const [formData, setFormData] = useState({
    blogTitle: "",
    blogSubTitle: "",
    path: "",
    post_id: "",
    image: "",
  });
  const [editorHtml, setEditorHtml] = useState("");
  const [alertMsgText, setalertMsgText] = useState("");
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    severity: "success",
  });
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");
  const [showHtml, setShowHtml] = useState(false);

  const { id } = useParams();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const { open, severity } = alertMsg;

  const handleClose = () => {
    setAlertMsg({ open: false, severity: { severity } });
  };

  const modules = {
    syntax: true,
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link", "image", "video", "formula"],
      [{ header: 1 }, { header: 2 }],

      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"], // remove formatting button
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
    "code",
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditorChange = (content) => {
    setEditorHtml(content);
  };
  const handleTextareaChange = (e) => {
    setEditorHtml(e.target.value);
  };

  const handleCreateBlog = async (e) => {
    e.preventDefault();
    if (!formData.blogTitle || !editorHtml || !formData.path) {
      setalertMsgText("Please fill in all fields.");
      setAlertMsg({ open: true, severity: "error" });
    } else {
      try {
        updateLoader(true);
        const response = await axios.post(
          id
            ? `${apiUrl}/clevermediumBackend/updatepost.php`
            : `${apiUrl}/clevermediumBackend/blogpost.php`,
          {
            title: formData.blogTitle,
            subtitle: formData.blogSubTitle,
            content: editorHtml,
            path: formData.path,
            post_id: formData.post_id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.status) {
          setalertMsgText(response.data.message);
          setAlertMsg({ open: true });
        }
        updateLoader(false);
      } catch (error) {
        console.error("Error during authentication:", error.message);
        updateLoader(false);
      }
    }
  };

  const getPostData = async () => {
    try {
      updateLoader(true);
      const response = await axios.post(
        `${apiUrl}/clevermediumBackend/getpost.php`,
        {
          path: id,
        }
      );
      /* eslint-disable no-debugger */
      // debugger;

      // setBlogs(response.data.data);
      setFormData({
        ...formData,
        blogTitle: response.data?.data?.title,
        blogSubTitle: response.data?.data?.subtitle,
        path: response.data?.data?.path,
        post_id: response.data?.data?.post_id,
        image: response.data?.data?.image,
      });
      setEditorHtml(response.data?.data?.content);
      updateLoader(false);
    } catch (error) {
      console.error("Error during authentication:", error.message);
      updateLoader(false);
    }
  };
  useEffect(() => {
    if (id) {
      getPostData();
    }
  }, []);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmitImage = async (e) => {
    e.preventDefault();
    if (!image) {
      setMessage("Please provide both a title and an image.");
      return;
    }

    const imageData = new FormData();
    imageData.append("image", image);
    imageData.append("post_id", formData.post_id);
    try {
      const response = await axios.post(
        `${apiUrl}/clevermediumBackend/upload_image.php`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setMessage("Image uploaded successfully!");
      } else {
        setMessage(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      setMessage("Error uploading image.");
    }
  };

  const handleConvert = () => {
    const converted = useConvertText(formData.path);
    if (converted) {
      setFormData({ ...formData, path: converted });
    }
  };

  const onDelete = async (post_id) => {
    try {
      const formData = new FormData();
      formData.append("post_id", post_id);

      const response = await axios.post(
        `${apiUrl}/clevermediumBackend/delete_image.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        setMessage("Image Deleted successfully!");
      } else {
        setMessage(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("An error occurred while deleting the image.");
    }
  };

  return (
    <DContainer maxWidth={1200} margin={"auto"}>
      <Stack
        py={2}
        alignItems={"center"}
        justifyContent="space-between"
        flexDirection={"row"}
      >
        <img width="250" src={logo} />

        {authenticated && <AccountMenu handleLogout={handleLogout} />}
      </Stack>

      {open && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {alertMsgText}
          </Alert>
        </Snackbar>
      )}
      <h1>Admin</h1>
      <Button onClick={() => setShowHtml(!showHtml)} variant="contained">
        {showHtml ? "Switch to Editor" : "Switch to HTML"}
      </Button>
      <Stack my={3}>
        <TextField
          fullWidth
          name="blogTitle"
          label="Blog Title"
          placeholder="Blog Title"
          multiline
          value={formData.blogTitle}
          onChange={handleInputChange}
        />
      </Stack>
      <TextField
        fullWidth
        name="blogSubTitle"
        label="Blog Sub Title"
        placeholder="Blog Sub Title"
        multiline
        value={formData.blogSubTitle}
        onChange={handleInputChange}
      />
      <Paper
        elevation={3}
        sx={{ padding: "15px", marginTop: 3, position: "relative" }}
      >
        {formData.image ? (
          <>
            <Typography variant="h6" gutterBottom>
              Blog Image
            </Typography>
            <img
              src={`${apiUrl}/clevermediumBackend/${formData.image}`}
              height={300}
            />
            <Button
              variant="outlined"
              onClick={() => onDelete(formData.post_id)}
              startIcon={<DeleteIcon />}
              sx={{ position: "absolute", right: "16px", top: "16px" }}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Upload Blog Image
            </Typography>
            <form onSubmit={handleSubmitImage}>
              <div>
                <label>Image:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <button type="submit">Upload</button>
            </form>
            {message && <p>{message}</p>}
          </>
        )}
      </Paper>
      <Stack my={3}>
        {showHtml ? (
          <textarea
            value={editorHtml || ""}
            onChange={handleTextareaChange}
            style={{ width: "100%", height: "300px" }}
          />
        ) : (
          <ReactQuill
            ref={quillRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={editorHtml}
            onChange={handleEditorChange}
            style={{ height: 25 + "em", marginBottom: "3em" }}
          />
        )}
      </Stack>
      <Stack mt={6} mb={3} display={"flex"} flexDirection={"row"}>
        <TextField
          fullWidth
          name="path"
          id="outlined-textarea"
          label="Path"
          placeholder="Path"
          value={formData.path}
          onChange={handleInputChange}
        />
        <Button
          sx={{ marginLeft: 2, width: "160px" }}
          onClick={handleConvert}
          variant="contained"
        >
          Convert Path
        </Button>
      </Stack>
      <Button onClick={handleCreateBlog} variant="contained">
        {id ? "Update Blog" : "Create Blog"}
      </Button>
    </DContainer>
  );
};

export default AdminBlog;
