import React from "react"; //  useState
// import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import { Logo, HeaderContainer, ToolbarContainer } from "./headerStyle";
// import logo from "../../assets/images/clevermedium-logo.png";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import CleverLogo from "../logo/logo";

const Header = () => {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const matches = useMediaQuery("(max-width:600px)");

  // const toggleMobileMenu = () => {
  //   setMobileMenuOpen(!mobileMenuOpen);
  // };

  return (
    <HeaderContainer position="static">
      <ToolbarContainer>
        <Logo py={1}>
          {/* <img style={{ maxWidth: "250px" }} loading="lazy" src={logo} /> */}
          <Link to="/">
            <CleverLogo />
          </Link>
        </Logo>
        {/* {matches && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </IconButton>
        )} */}
      </ToolbarContainer>
      {/* {mobileMenuOpen && (
        <div className="mobile-menu">
          <Typography variant="body1">Mobile Menu Item 1</Typography>
          <Typography variant="body1">Mobile Menu Item 2</Typography>
          <Typography variant="body1">Mobile Menu Item 3</Typography>
        </div>
      )} */}
    </HeaderContainer>
  );
};

export default Header;
