import { ThemeProvider } from "./theme/themeProvider";
import AppRouter from "./routers/routers";
import { AppContext } from "./context/AppContext";
import Loader from "./components/loader/loader";
import { useContext } from "react";

const App = () => {
  const { state } = useContext(AppContext);
  return (
    <ThemeProvider>
      <AppRouter />
      {state.loader && <Loader show={state.loader} />}
    </ThemeProvider>
  );
};

export default App;
