import { FooterContainer } from "./footerStyle";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const Footer = () => {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <FooterContainer>
      <p>©{year} Clevermedium. All rights reserved.</p>
      <Box>
      <Link px={2} href="/about-us" underline="none" color={'#000'}>
        About us
      </Link>
      <Link href="/terms-and-conditions" underline="hover" color={'#000'}>
        Terms
      </Link>
    </Box>
    </FooterContainer>
  );
};

export default Footer