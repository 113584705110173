import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout, isAuthenticated } from "../../routers/auth";
import "react-quill/dist/quill.snow.css";
import { DContainer } from "../adminblog/adminblogStyle";
import { Box, Stack } from "@mui/material";
import logo from "../../assets/images/clevermedium-logo.png";
import AccountMenu from "../accountMenu/accountMenu";
import BasicTable from "../components/table/table";
import axios from "axios";
import { AppContext } from "../../context/AppContext";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AdminBlogList = () => {
  const { state, updatebloglist, updateLoader } = useContext(AppContext);
  const { bloglist } = state;
  const navigate = useNavigate();
  const authenticated = isAuthenticated();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const getAllPostData = async () => {
    try {
      updateLoader(true);
      const response = await axios.get(
        `${baseUrl}/clevermediumBackend/getallpost.php`
      );
      updatebloglist(response.data.data);
      updateLoader(false);
    } catch (error) {
      console.error("Error during authentication:", error.message);
    }
  };
  useEffect(() => {
    getAllPostData();
  }, []);

  const onEditBlog = (path) => {
    navigate(`/admin/adminblog/${path}`);
  };
  return (
    <DContainer maxWidth={1200} margin={"auto"}>
      <Stack
        py={2}
        alignItems={"center"}
        justifyContent="space-between"
        flexDirection={"row"}
      >
        <img width="250" src={logo} />

        {authenticated && <AccountMenu handleLogout={handleLogout} />}
      </Stack>
      <Box>
        {bloglist && bloglist.length > 0 && (
          <BasicTable bloglist={bloglist} onEditBlog={onEditBlog} />
        )}
      </Box>
    </DContainer>
  );
};

export default AdminBlogList;
