import styled from "styled-components";

export const FooterContainer = styled.footer`
  border-top: 1px solid #eee;
  background-color: #fff;
  color: #000;
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
