import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import { useNavigate } from "react-router-dom";
import useShare from "../../hooks/useShare";

const BlogCard = ({ blog }) => {
  const { title, subtitle, path } = blog;
  const navigate = useNavigate();
  const { isSupported, shareContent } = useShare(path, title);
  const redirectBlogPage = (e) => {
    e.preventDefault();
    navigate(`/blog/${blog.path}`);
  };
  return (
    <Card sx={{ height: "100%" }} onClick={redirectBlogPage}>
      {/* <CardMedia
                sx={{ height: 140 }}
                image="https://miro.medium.com/v2/resize:fill:200:134/1*og9Y-jMqTxd7WKBOP0rnSw.jpeg"
                title="green iguana"
              /> */}
      <CardContent sx={{paddingBottom:0}}>
        <Typography fontSize={"1em"} gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <IconButton size="small" aria-label="add to favorites">
          <FavoriteIcon fontSize="small" />
        </IconButton>
        <IconButton
          onClick={shareContent}
          disabled={!isSupported}
          aria-label="share"
        >
          <ShareIcon fontSize="small" />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default BlogCard;
