import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./auth";

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => {
//       if (isAuthenticated() && !isTokenExpired()) {
//         return <Component {...props} />;
//       } else {
//         return <Navigate to ="/admin" />;
//       }
//     }}
//   />
// );

const PrivateRoute = ({ children }) => {
  const authenticated = isAuthenticated();
  return authenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
