import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const BasicTable = ({ bloglist, onEditBlog }) => {
  const onEdit = (path) => {
    onEditBlog(path);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Post ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Created Date</TableCell>
            <TableCell>Path</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bloglist.map((row) => (
            <TableRow
              key={row.post_id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.post_id}
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.username}</TableCell>
              <TableCell>{row.created_at}</TableCell>
              <TableCell>{row.path}</TableCell>
              <TableCell>
                <ModeEditIcon onClick={()=>onEdit(row.path)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
