import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import TimeAgo from "./../../hooks/TimeAgo";
import useShare from "../../hooks/useShare";

const BloggerProfile = ({ name, date, blog }) => {
  const { isSupported, shareContent } = useShare(
    blog.path,
    blog.title,
    blog.content
  );

  return (
    <Stack
      direction="row"
      pb={2}
      justifyContent={"space-between"}
      style={{ borderBottom: "1px solid #eee" }}
    >
      <Stack spacing={2} direction="row">
        <Avatar>H</Avatar>
        <Stack direction="column">
          <Typography textTransform={'capitalize'} variant="body2">{name}</Typography>
          <Stack fontSize={14} direction="row" spacing={2}>
            <TimeAgo date={date} />
          </Stack>
        </Stack>
      </Stack>
      <IconButton
        onClick={shareContent}
        disabled={!isSupported}
        aria-label="share"
      >
        <ShareIcon />
      </IconButton>
    </Stack>
  );
};

export default BloggerProfile;
