import { useEffect, useState } from 'react';

const useShare = (url, title) => {
  const [isSupported, setIsSupported] = useState(false);

  // Check if the Web Share API is supported
  useEffect(() => {
    if (navigator.share) {
      setIsSupported(true);
    }
  }, []);

  // Function to share the content
  const shareContent = async (e) => {
    e.stopPropagation(); 
    if (isSupported) {
      try {
        await navigator.share({
          title,
          url,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing content: ', error);
      }
    } else {
      console.log('Web Share API not supported on this browser.');
      // Fallback: Maybe show a custom share dialog or provide a link to copy
    }
  };

  return { isSupported, shareContent };
};

export default useShare;
