import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useTheme } from './../../theme/themeProvider';
import Header from '../../components/header/header';
import { Container, MainContainer } from './mainStyle';
import Footer from '../../components/footer/footer';

export const MainLayout = ({children}) => {
    const { theme } = useTheme();
 

    return (
        <MuiThemeProvider theme={theme}>
            <Container>
                <Header />
                <MainContainer>
                    {/* <h2>Main Content</h2>
                    <Button variant="contained" color="primary" onClick={toggleTheme}>
                        Toggle Theme
                    </Button> */}
                    {/* Your main content goes here */}
                    {children}
                </MainContainer>
                <Footer/>
            </Container>
        </MuiThemeProvider>
    );
}