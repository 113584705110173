import React from "react";
import { MainLayout } from "../../layouts/main/main";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const Terms = () => {
  const matches = useMediaQuery("(max-width:768px)");
  return (
    <MainLayout>
      <Stack my={3}>
        <Typography
          variant="h1"
          component="h1"
          sx={{ fontWeight: "bold" }}
          fontSize={matches ? "2em" : "1.5em"}
          mb={1}
        >
          Terms and Conditions
        </Typography>
        {/* <p>Effective Date: [Insert Date] </p> */}
        <p>
          Welcome to <a href="https://clevermedium.com/">clevermedium.com</a>!
          By accessing or using our website, you agree to comply with and be
          bound by the following terms and conditions. Please read them
          carefully before using our services.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          1. Acceptance of Terms
        </Typography>
        <p>
          By using <a href="https://clevermedium.com/">clevermedium.com</a>, you
          acknowledge that you have read, understood, and agreed to these Terms
          and Conditions. If you do not agree, you are prohibited from accessing
          or using this site.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          2. Use of Content
        </Typography>
        <ul>
          <li>
            All content on{" "}
            <a href="https://clevermedium.com/">clevermedium.com</a>, including
            coding tutorials, technology insights, and programming stories, is
            for informational and educational purposes.
          </li>
          <li>
            You may use the content for personal, non-commercial purposes,
            provided you do not modify or misrepresent it.
          </li>
          <li>
            Redistribution, reproduction, or commercial use of any content
            without prior written permission is strictly prohibited.
          </li>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          3. User Obligations
        </Typography>
        <p>
          When using <a href="https://clevermedium.com/">clevermedium.com</a>,
          you agree to:
          <ul>
            <li>Abide by applicable laws and regulations.</li>
            <li>
              Provide accurate information if required for any interaction with
              the site.
            </li>
            <li>
              Refrain from engaging in activities that may disrupt or harm the
              website’s functionality or reputation.
            </li>
          </ul>
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          4. Intellectual Property Rights
        </Typography>
        <ul>
          <li>
            All intellectual property on{" "}
            <a href="https://clevermedium.com/">clevermedium.com</a>, including
            logos, text, graphics, and code, belongs to the site owners unless
            otherwise stated.
          </li>
          <li>
            Unauthorized use of{" "}
            <a href="https://clevermedium.com/">clevermedium.com</a>{" "}
            intellectual property is a violation of applicable laws and may
            result in legal action.
          </li>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          5. Third-Party Links
        </Typography>
        <p>
          <a href="https://clevermedium.com/">clevermedium.com</a> may include
          links to third-party websites for reference or convenience. We do not
          endorse or take responsibility for the content or practices of these
          external sites. Accessing third-party links is at your own risk.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          6. Limitations of Liability
        </Typography>
        <p>
          <a href="https://clevermedium.com/">clevermedium.com</a> is not liable
          for any damages arising from:
          <ul>
            <li>The use or inability to use the site.</li>
            <li>Reliance on any information provided on the site.</li>
            <li>Errors, omissions, or inaccuracies in the content.</li>
          </ul>
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          7. Disclaimer of Warranties
        </Typography>
        <ul>
          <li>
            <a href="https://clevermedium.com/">clevermedium.com</a> provides
            content “as is” and does not guarantee its accuracy, reliability, or
            suitability for specific purposes.
          </li>
          <li>
            We disclaim all warranties, express or implied, to the fullest
            extent permitted by law.
          </li>
        </ul>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          8. Termination of Access
        </Typography>
        <p>
          <a href="https://clevermedium.com/">clevermedium.com</a> reserves the
          right to suspend or terminate your access to the website at its
          discretion, without prior notice, for violations of these Terms or
          other reasons.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          9. Modifications to Terms
        </Typography>
        <p>
          We may revise these Terms and Conditions at any time without prior
          notice. By continuing to use the website, you agree to be bound by the
          updated terms.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          10. Governing Law
        </Typography>
        <p>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of india. Any disputes will be resolved exclusively in
          the courts of india.
        </p>
        <Typography
          variant="h2"
          component="h2"
          sx={{ fontWeight: "bold" }}
          fontSize={"1.2rem"}
          my={1}
        >
          11. Contact Information
        </Typography>
        <p>
          For questions or concerns regarding these Terms and Conditions, please
          contact us at:
        </p>
        <b>Email: </b>{" "}
        <a href="mailto:contact@clevermedium.com">contact@clevermedium.com</a>
      </Stack>
    </MainLayout>
  );
};

export default Terms;
