import React from "react";
import "./loader.css";
import { Backdrop, CircularProgress } from "@mui/material";

const Loader = ({ show }) => {
  if (!show) return null;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
