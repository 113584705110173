// components/BlogDetail.js
import React from "react";
import BloggerProfile from "../bloggerProfile/bloggerProfile";
import Typography from "@mui/material/Typography";
import { Stack, useMediaQuery } from "@mui/material";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import HelmetComponet from "../helmet/helmet";

const BlogDetail = ({ blog }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const matches = useMediaQuery("(min-width:768px)");
  if (!blog) {
    return <div>Blog not found</div>;
  }
  const sanitizedContent = DOMPurify.sanitize(blog.content);
  const renderContent = (html) => {
    return parse(html, {
      replace: (node) => {
        if (node.name === "code" && node.attribs && node.attribs.class) {
          const language = node.attribs.class.split("-")[1]; // e.g., 'language-javascript'
          return (
            <SyntaxHighlighter style={dark} language={language}>
              {node.children[0]?.data.trim()}
            </SyntaxHighlighter>
          );
        }
      },
    });
  };

  return (
    <Stack my={3}>
      <HelmetComponet title={blog.title} description={blog.subtitle} path={blog.path} />
      <Typography
        variant="h1"
        component="h1"
        sx={{ fontWeight: "bold" }}
        maxWidth={800}
        mb={2}
        fontSize={matches ? "2em" : "1.5em"}
      >
        {blog.title}
      </Typography>
      {blog.subtitle && (
        <Typography
          variant="h2"
          component="h4"
          maxWidth={800}
          fontSize={matches ? "1.2em" : "1em"}
          mb={3}
        >
          {blog.subtitle}
        </Typography>
      )}
      <BloggerProfile name={blog.username} date={blog.created_at} blog={blog} />
      {blog.image && (
        <img
          src={`${baseUrl}/clevermediumBackend/${blog.image}`}
          alt={blog.title}
        />
      )}
      <Stack className="cm-main--blog">{renderContent(sanitizedContent)}</Stack>
    </Stack>
  );
};

export default BlogDetail;
