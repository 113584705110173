import { styled } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Styled component for the logo
const Logo = styled("div")`
  flex-grow: 1;
  line-height: 0;
`;

// Styled component for the menu list
const MenuList = styled("div")`
  display: flex;
  gap: 20px;
`;

// Styled component for the header container
const HeaderContainer = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: none;
    border-bottom: 1px solid #eee;
    @media (max-width: 768px) {
      /* Mobile styling */
      .MuiToolbar-root {
        justify-content: space-between;
      }
    }
  }
`;
const ToolbarContainer = styled(Toolbar)`
  justify-content: space-between;
  padding: 10px 16px;
`;

export { Logo, MenuList, HeaderContainer, ToolbarContainer };
