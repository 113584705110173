// theme.js

import { createTheme } from '@mui/material/styles';

// Define your light theme
const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffffff', // Adjust this color as needed
    },
    secondary: {
      main: '#FF9800', // Adjust this color as needed
    },
  },
});

// Define your dark theme
const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#2196F3', // Adjust this color as needed
    },
    secondary: {
      main: '#FF9800', // Adjust this color as needed
    },
  },
});

export { lightTheme, darkTheme };
