import React from "react";
import Box from "@mui/material/Box";
import BlogCard from "../blogCard/blogCard";
import { Grid, Typography, useMediaQuery } from "@mui/material";

const BlogList = ({ blogs }) => {
  const matches = useMediaQuery('(min-width:768px)');
  return (
    <Box sx={{ flexGrow: 1 }} mt={2}>
      <Typography
        textAlign={"center"}
        fontWeight={700}
        fontSize={matches ? "2em" : "1.5em"}
        variant="h1"
      >
        Clevermedium Blog
      </Typography>
      <Typography textAlign={"center"} variant="h6" fontSize={'1em'}>
        I write articles about Web Development and Cloud Computing.
      </Typography>
      <Typography
        mb={3}
        textAlign={"center"}
        variant="overline"
        display="block"
        gutterBottom
      >
        - Written by Kesavamoorthi R
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {blogs.map((blog) => (
          <Grid item xs={12} key={blog.post_id}>
            <BlogCard blog={blog} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogList;
