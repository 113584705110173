import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';

const TimeAgo = ({ date }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const parsedDate = new Date(date);
    
    // Check if the parsed date is valid
    if (isNaN(parsedDate)) {
      setTimeAgo('Invalid date');
      return;
    }

    const interval = setInterval(() => {
      setTimeAgo(formatDistanceToNow(parsedDate, { addSuffix: true }));
    }, 60000); // Update every minute

    // Initial update
    setTimeAgo(formatDistanceToNow(parsedDate, { addSuffix: true }));

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [date]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
