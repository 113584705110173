// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blog from "../pages/blog/blog";
import BlogDetailPage from "../pages/blogDetailsPage/blogDetailsPage";
import AboutUs from "../pages/aboutUs/aboutUs";
import Terms from "../pages/terms/terms";
import AdminSignIn from "../admin/signIn/signIn";
import PrivateRoute from "./PrivateRoute";
import { PageNotFound } from "../pages/pageNotFound/pageNotFound";
import AdminBlogList from "../admin/bloglist/blogList";
import AdminBlog from "../admin/adminblog/adminblog";

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/login" element={<AdminSignIn />} />
        <Route
          path="/admin/adminblog/:id"
          element={
            <PrivateRoute>
              <AdminBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/adminblog"
          element={
            <PrivateRoute>
              <AdminBlog />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/bloglist"
          element={
            <PrivateRoute>
              <AdminBlogList />
            </PrivateRoute>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/blog/:id" element={<BlogDetailPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
